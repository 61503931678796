import { Gender } from "src/app/core/enums/gender.enum";
import { DateUtil } from "src/app/core/utility/date.util";
import { ObjectUtil } from "src/app/core/utility/object.util";

export class TopClientReportItemModel {
    public rowNumber: number;
    public reporterName: string;
    public completeName: string;
    public id: number;
    public gender: Gender;
    public phone: string;
    public mobileNumber: string;
    public city: string;
    public lastPurchase: Date;
    public country: string;
    public company: string;
    public clientRating: string;
    public preTaxAmount: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            lastPurchase: DateUtil.fromString
        });
    }
}
