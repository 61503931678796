import { AlterationType } from '../../../enums/alteration-type.enum';
import { DateUtil } from "src/app/core/utility/date.util";
import { ObjectUtil } from "src/app/core/utility/object.util";
import { ItemStatus } from 'src/app/core/enums/item-status.enum';

export class AlterationReportItemsModel {
    public id: number;
    public rowNumber: number;
    public repName: string;
    public repId: number;
    public clientName: string;
    public clientId: number;
    public orderId: number;
    public province: string;
    public exchangeToCad: number;
    public item: string;
    public line: string;
    public alterationType: AlterationType;
    public retailPrice: number;
    public reason: string;
    public alteration: string;
    public alterationFee: number;
    public alterationFeeInCad: number;
    public receiptLink: string;
    public dateApproved: Date;
    public status: ItemStatus;
    public approver: string;
    public dateOfOrder: Date;
    public dateOfAlteration: Date;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            dateOfOrder: DateUtil.fromString,
            dateOfAlteration: DateUtil.fromString,
            dateApproved: DateUtil.fromString,
        });
    }
}
