import { ObjectUtil } from "src/app/core/utility/object.util";

export class TotalNCPBankReportItemsModel {
    public rowNumber: number;
    public repName: string;
    public assignedMonth: string;
    public rollOver: number;
    public carryForward: number;
    public ncpUsed: number;
    public ncpEarned: number;
    public nonUsedENCP: number;
    public totalBank: number;
    public addedNCP: number;
    public addedNCPReason: string;
    public uploadedOrders: number;
    public availableNcp: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}