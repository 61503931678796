import { ObjectUtil } from "../../utility/object.util";

export class ItemReportTableModel {
    public reporter: string;
    public salesInCAD: number;
    public averagePackageSizeInCAD: number;
    public newSalesNonDiscounted: number;
    public newSales: number;
    public ownNonDiscountedNewSales: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
