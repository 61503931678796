import { DateUtil } from "src/app/core/utility/date.util";
import { ObjectUtil } from "src/app/core/utility/object.util";

export class RemakeItemReportItemModel {
    public rowNumber: number;
    public orderId: number;
    public itemType: string;
    public itemLine: string;
    public repName: string;
    public dateOfRemake: Date;
    public clientName: string;
    public clientId: number;
    public clientLocation: string;
    public isAvailable: boolean;
    public fileLink: string;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            dateOfRemake: DateUtil.fromString,
        });
    }
}
