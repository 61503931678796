import { ObjectUtil } from "src/app/core/utility/object.util";

export class ProfitLossReportItemModel {
    public rowNumber: number;
    public office: string;
    public repName: string;
    public cashflow: number;
    public remakesAndAltsCost: number;
    public repPay: number;
    public managementPay: number;
    public teamManagementPay: number;
    public companyPaidExpenses: number;
    public profit: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
