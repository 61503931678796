import { DateUtil } from "src/app/core/utility/date.util";
import { ObjectUtil } from "src/app/core/utility/object.util";

export class OverdueReportItemsModel {
    public rowNumber: number;
    public orderId: number;
    public clientId: number;
    public writeOffDate: Date;
    public repName: string;
    public orderStatus: string;
    public clientName: string;
    public retailPrice: number;
    public provinceOfSale: string;
    public company: string;
    public currency: string;
    public cityOfSale: string;
    public salesTax: number;
    public cadRate: number;
    public salesTaxInCad: number;
    public retailPriceInCad: number;
    public totalCollectedWithTaxInCad: number;
    public totalCollectedWithTax: number;
    public dateOfOrder: Date;
    public salesTaxPercentage: number;
    public repPayPercentage: number;
    public payRep: number;
    public payRepInCad: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            dateOfOrder: DateUtil.fromString,
            writeOffDate: DateUtil.fromString,
        });
    }
}
