import { DateUtil } from '../../../utility/date.util';
import { ObjectUtil } from '../../../utility/object.util';

export class MySalesReportItemModel {
    public rowNumber: number;
    public repName: string;
    public orderStatus: string;
    public clientName: string;
    public clientId: number;
    public orderId: number;
    public firm: string;
    public item: string;
    public line: string;
    public provinceOfSale: string;
    public currency: string;
    public cadRate: number;
    public retailPrice: number;
    public retailPriceInCad: number;
    public salesTax: number;
    public salesTaxInCad: number;
    public totalCollectedWithTaxInCad: number;
    public totalCollectedWithTax: number;
    public dateOfOrder: Date;
    public salesTaxPercentage: number;
    public payRep: number;
    public payRepInCad: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            dateOfOrder: DateUtil.fromString,
        });
    }
}
