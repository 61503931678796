import { UserContextService } from 'src/app/core/service/user-context.service';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType, LayoutPosition } from 'chart.js';
import sum from 'lodash-es/sum';
import moment from 'moment';
import uniqueId from 'lodash-es/uniqueId';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ChartComponent } from '../chart/chart.component';
import { NgClass, NgStyle } from '@angular/common';
import { FormattingUtil } from 'src/app/core/utility/formatting.util';

@Component({
    selector: 'bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ChartComponent, NgxSpinnerModule, NgStyle, NgClass]
})
export class BarChartComponent implements OnInit, OnChanges {
    @Input() public labels: string[];
    @Input() public withLegend = true;
    @Input() public isStacked = false;
    @Input() public horizontal = false;
    @Input() public showDatalabels = true;
    @Input() public labelAsDateFormat = null;
    @Input() public xAxisLabel: string;
    @Input() public yAxisLabel: string;
    @Input() public axisStepsCount = 12;
    @Input() public maxScale?: number;
    @Input() public stepSize?: number;
    @Input() public valuePostfix = '';
    @Input() public data: ChartDataset[];
    @Input() public avarageValue: number;
    @Input() public maintainAspectRatio = true;
    @Input() public height: number;
    @Input() public width: number;
    @Input() public labelPosition: LayoutPosition = 'bottom';
    @Input() public isAverageLineVisible = false;
    @Input() public isLoading = false;
    @Input() public gridColor = '#4F4F4F';
    @Input() public icon = '/assets/charts/icon.svg';
    @Input() public placeholderImage = '/assets/charts/chart-background.svg';
    @Input() public placeholderText = "Nothing to show for selected filter";
    @Input() public chartTitle;
    @Input() public additionalTitle;
    public loaderImg = "<img src='assets/crown.png'/>";

    public barChartType: ChartType = 'bar';
    public spinnerName = "";
    public isDarkTheme = false;

    constructor(private spinner: NgxSpinnerService, userContextService: UserContextService,
        private changeDetectorRef: ChangeDetectorRef) {
        userContextService.themeState.subscribe(x => {
            this.loaderImg = x ? "<img src='assets/crown.png'/>" : "<img style='transform: scale(1.6);' src='assets/crown-dark.png'/>";
            this.isDarkTheme = x;
            this.isLoading = true;
            setTimeout(() => {
                this.isLoading = false;
                this.changeDetectorRef.detectChanges();
            }, 100);
            if (this.data) {
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    public ngOnInit(): void {
        this.spinnerName = uniqueId('spiner_');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.isLoading) {
            if (changes.isLoading.currentValue) {
                this.spinner.show(this.spinnerName);
            }
            else {
                this.spinner.hide(this.spinnerName);
            }
            this.changeDetectorRef.detectChanges();
        }
    }

    public get options(): ChartOptions {
        const options = this.createChartOptions();

        if (this.isStacked) {
            this.configureStackedBar(options);
        }

        if (this.isAverageLineVisible) {
            this.configureAverage(options);
        }

        return options;
    }

    private createChartOptions(): ChartOptions {
        const defaultOptions: ChartOptions = {
            responsive: true,
            maintainAspectRatio: this.maintainAspectRatio,
            hover: { mode: null },
            plugins: {
                legend: { position: this.labelPosition, labels: { usePointStyle: true } }
            },
            indexAxis: this.horizontal ? 'y' : 'x'
        };

        if (this.labelAsDateFormat) {
            this.setupDateAxisOptions(defaultOptions);
        } else {
            this.setupScales(defaultOptions);
            this.setupPlugins(defaultOptions);
        }

        return defaultOptions;
    }

    private setupDateAxisOptions(options: ChartOptions) {
        const minScale = Math.min(...this.data.map((d) => Math.min(...(d.data as number[]))));
        const maxScale = Math.max(...this.data.map((d) => Math.max(...(d.data as number[]))));

        const dayAsNumber = moment(new Date().setDate(2)).unix() - moment(new Date().setDate(1)).unix();
        const stepSize = minScale === maxScale ? dayAsNumber : (maxScale - minScale) / this.axisStepsCount;

        const defaultScale = {
            min: minScale - stepSize,
            max: maxScale + stepSize,
            ticks: {
                stepSize,
                callback: (label) => moment.unix(label).format(this.labelAsDateFormat),
                autoSkip: false
            }
        };

        options.plugins.datalabels = { display: false };
        options.plugins.tooltip = {
            mode: 'point',
            callbacks: {
                label: (tooltipItems) => moment.unix(tooltipItems.raw as number).format(this.labelAsDateFormat)
            }
        };

        options.scales = {
            x: this.horizontal ? defaultScale : { ticks: { autoSkip: false } },
            y: !this.horizontal ? defaultScale : { ticks: { autoSkip: false } }
        };
    }

    private setupScales(options: ChartOptions) {
        const stepSize = this.stepSize ? this.stepSize : this.getDefaultStepSize();
        const maxScale = this.maxScale ? this.maxScale : stepSize * this.axisStepsCount;

        const defaultScale = {
            max: maxScale > 0 ? maxScale + 1 : 1,
            grid: { color: this.gridColor },
            ticks: { stepSize, min: 0, max: maxScale, callback: (label) => label + this.valuePostfix, autoSkip: false }
        };
        options.scales = {
            x: this.horizontal ? defaultScale : { ticks: { autoSkip: false }, grid: { color: this.gridColor } },
            y: !this.horizontal ? defaultScale : { ticks: { autoSkip: false }, grid: { color: this.gridColor } }
        };

        this.setupLabels(options);
    }

    private getDefaultStepSize(): number {
        const maxBarLenght = Math.max(...this.data.map((d) => Math.max(...d.data.map(x => Math.abs(x as number)))));
        const potentialStepSize = Math.abs(maxBarLenght / (this.axisStepsCount - 1));
        return potentialStepSize > 1 ? Math.floor(potentialStepSize) + 1 : 1;
    }

    private setupLabels(options: ChartOptions) {
        if (this.xAxisLabel) {
            (options.scales.x as any).title = { text: this.xAxisLabel, display: true };
        }
        if (this.yAxisLabel) {
            (options.scales.y as any).title = { text: this.yAxisLabel, display: true };
        }
    }

    private setupPlugins(options: ChartOptions) {
        options.plugins.datalabels = {
            display: this.showDatalabels,
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: (value, _) => value === 0 ? '' : value + this.valuePostfix
        };

        options.plugins.tooltip = {
            mode: 'point'
        };

    }

    private configureStackedBar(options: ChartOptions) {
        (options.scales.x as any).stacked = true;
        (options.scales.y as any).stacked = true;

        if (options.plugins.datalabels) {
            options.plugins.datalabels.anchor = 'center';
            options.plugins.datalabels.align = 'center';
        }
        options.scales.y.max = this.getMaxTicksOfYAxesForStack() + 1;
    }

    private getMaxTicksOfYAxesForStack() {
        let max = 0;
        for (let i = 0; i < this.data[0].data.length; i++) {
            max = Math.max(max, sum(this.data.map((d) => d.data[i])));
        }
        return max;
    }

    private configureAverage(options) {
        var values = this.data[0].data;
        var average = this.avarageValue ? this.avarageValue : values.reduce((a: any, b: any) => a + b, 0) / values.length
        options.plugins.annotation = {
            annotations: [{
                type: 'line',
                borderColor: 'black',
                borderDash: [6, 6],
                borderDashOffset: 0,
                borderWidth: 3,
                label: {
                    display: true,
                    content: 'Average: ' + '$ ' + FormattingUtil.getCommaSeparatedNumber(Number(average.toFixed(2))),
                    position: 'end',
                    yValue: 60
                },
                scaleID: 'y',
                value: (ctx) => average
            }]
        }
    }
}
