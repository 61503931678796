import { DateUtil } from "src/app/core/utility/date.util";
import { ObjectUtil } from "src/app/core/utility/object.util";

export class ClientReassignReportItemModel {
    public rowNumber: number;
    public repId: number;
    public clientId: number;
    public completeName: string;
    public clientRating: string;
    public reportName: string;
    public city: string;
    public country: string;
    public province: string;
    public creationDate: Date;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            creationDate: DateUtil.fromString,
        });
    }
}
