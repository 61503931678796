import { ObjectUtil } from "src/app/core/utility/object.util";

export class TopServiceReportItemModel {
    public rowNumber: number;
    public dealer: string;
    public rating: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
