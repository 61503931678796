import { UserContextService } from './../../../core/service/user-context.service';
import { Component, ElementRef, Input, NgZone, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Chart, ChartDataset, ChartOptions, ChartType, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AnnotationPlugin from "chartjs-plugin-annotation";

@Component({
    selector: 'base-chart',
    template: '',
    styles: [':host { display: block; }'],
    standalone: true
})
export class ChartComponent implements OnInit, OnChanges {
    @Input() public datasets: ChartDataset[];
    @Input() public labels: any[];
    @Input() public chartType: ChartType;
    @Input() public options: ChartOptions;
    @Input() public height: number;
    @Input() public width: number;

    private canvas: HTMLCanvasElement;
    private chart: Chart;
    private isDarkTheme: boolean;

    constructor(private elementRef: ElementRef, private ngZone: NgZone,
        private userContextService: UserContextService) {
        Chart.register(...registerables);
        Chart.register(AnnotationPlugin);
        Chart.register(ChartDataLabels);
        Chart.register({
            id: 'customCanvasBackgroundColor',
            beforeDraw: (chart, args, options) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = options.color || '#99ffff';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            }
        });
    }

    public ngOnInit(): void {
        if (!this.displayLabels()) {
            if (!this.options.plugins.legend) {
                this.options.plugins.legend = { display: false };
            }
            else {
                this.options.plugins.legend.display = false;
            }
        }
        this.userContextService.themeState.subscribe(isDarkTheme => {
            this.isDarkTheme = isDarkTheme;
            this.create();
        });
    }

    public ngOnChanges(changes): void {
        if (!this.chart) {
            return;
        }

        if (changes.ChartType || changes.options) {
            this.create();
        } else {
            if (changes.datasets) {
                this.chart.data.datasets = changes.datasets.currentValue;
                this.chart.update();
            }
            if (changes.labels) {
                this.chart.data.labels = changes.labels.currentValue;
                this.chart.update();
            }
        }
    }

    private create(): void {
        this.ngZone.runOutsideAngular(() => {
            if (this.canvas) {
                this.elementRef.nativeElement.removeChild(this.canvas);
            }
        });

        (this.options.plugins as any).customCanvasBackgroundColor = {
            color: this.isDarkTheme ? '#272727' : 'white'
        };
        Chart.defaults.color = this.isDarkTheme ? '#C3C3C3' : 'black';
        this.options.color = this.isDarkTheme ? '#C3C3C3' : 'black';
        Chart.defaults.font.family = 'DIN';
        Chart.defaults.font.size = 16;
        Chart.defaults.font.weight = 400;

        this.canvas = document.createElement('canvas');
        if (this.options.maintainAspectRatio === false) {
            if (this.height) {
                this.canvas.style.height = this.height + 'px';
            }
            if (this.width) {
                this.canvas.style.width = this.width + 'px';
            }
        }

        this.elementRef.nativeElement.appendChild(this.canvas);
        this.chart = new Chart(this.canvas, {
            type: this.chartType,
            data: {
                labels: this.labels,
                datasets: this.datasets
            },
            options: this.options
        });
    }

    private displayLabels(): boolean {
        if (this.chartType === 'pie' || this.chartType === 'line') {
            return true;
        }

        const label = this.datasets.find(x => {
            if (x.label) { return true; }
        });

        if (label === undefined) {
            return false;
        }

        return true;
    }
}
