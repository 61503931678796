import { DateUtil } from "src/app/core/utility/date.util";
import { ObjectUtil } from "src/app/core/utility/object.util";

export class CashflowReportItemsModel {
    public rowNumber: number;
    public repName: string;
    public repId: number;
    public orderStatus: string;
    public clientName: string;
    public clientId: number;
    public orderId: number;
    public province: string;
    public exchangeToCad: number;
    public currency: number;
    public cashflow: number;
    public cashflowWithTax: number;
    public cashflowInCad: number;
    public cashflowWithTaxInCad: number;
    public units: number;
    public dateOfOrder: Date;
    public dateOfCashflow: Date;
    public terminal: string;
    public office: string;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            dateOfOrder: DateUtil.fromString,
            dateOfCashflow: DateUtil.fromString,
        });
    }
}
