import { NewClientReportItemModel } from 'src/app/core/models/reports/new-client-report/new-client-report-item.model';
import { ClientReassignReportItemModel } from './../models/reports/client-reassign-report/client-reassign-report-item.model';
import { ClientReassignReportFilterModel } from './../models/reports/client-reassign-report/client-reassign-report-filter.model';
import { TotalNCPBankReportFilterModel } from './../models/reports/total-ncp-bank-report/total-ncp-bank-report-filter.model';
import { TotalNCPBankReportItemsModel } from './../models/reports/total-ncp-bank-report/total-ncp-bank-report-item.model';
import { BookingBuddyReportItemsModel } from '../models/reports/booking-buddy-report/booking-buddy-report-item.model';
import { OverdueReportItemsModel as OverdueReportItemModel } from './../models/reports/overdue-report/overdue-report-item.model';
import { OverdueReportFilterModel } from './../models/reports/overdue-report/overdue-report-filter.model';
import { CashflowTrailorsReportItemModel } from './../models/reports/сashflow-trailors-report/сashflow-trailors-report-item.model';
import { CashflowTrailorsReportFilterModel } from './../models/reports/сashflow-trailors-report/сashflow-trailors-report-filter.model';
import { AlterationReportItemsModel } from './../models/reports/alteration-report/alteration-report-item.model';
import { AlterationReportFilterModel } from '../models/reports/alteration-report/alteration-report-fliter.model';
import { CashflowReportItemsModel as CashflowReportItemModel } from './../models/reports/cashflow-report/cashflow-report-item.model';
import { CashflowReportFilterModel } from '../models/reports/cashflow-report/cashflow-report-fliter.model';
import { ExpensesReportItemModel } from './../models/reports/expenses-report/expenses-report-item.model';
import { ExpensesReportFilterModel } from './../models/reports/expenses-report/expenses-report-filter.model';
import { ItemsReportFilterModel } from '../models/reports/items-report/items-report-filter.model';
import { ItemsReportItemModel } from '../models/reports/items-report/items-report-items.model';
import { HttpRestService } from './http-client/http-rest-client.service';
import { ModelList } from '../models/models-list';
import { MySalesReportItemModel } from './../models/reports/my-sales-report/my-sales-report-item.model';
import { MySalesReportFilterModel } from './../models/reports/my-sales-report/my-salse-report-filter.model';
import { BaseService } from "./base.service";
import { Injectable } from '@angular/core';
import { BookingBuddyReportFilterModel } from '../models/reports/booking-buddy-report/booking-buddy-report-filter.model';
import { PaymentReportItemsModel as PaymentReportItemModel } from '../models/reports/payment-report/payment-report-item.model';
import { PaymentReportFilterModel } from '../models/reports/payment-report/payment-report-filter.model';
import { OverviewReportItemsModel } from '../models/reports/overview-report/overview-report-items.model';
import { OverviewReportFilterModel } from '../models/reports/overview-report/overview-report-filter.model';
import { NewClientReportFilterModel } from '../models/reports/new-client-report/new-client-report-filte.model';
import { TopClientReportFilterModel } from '../models/reports/top-client-report/top-client-report-filter.model';
import { TopClientReportItemModel } from '../models/reports/top-client-report/top-client-report-item.model';
import { TopServiceReportItemModel } from '../models/reports/top-service-report/top-service-report-item.model';
import { FilterReportBaseModel } from '../models/reports/filter-report-base.model';
import { ClothierQBReportItemModel } from '../models/reports/clothier-qb-report/clothier-qb-report-item.model';
import { ManagementReportFilterModel } from '../models/reports/management-report/management-report-filter.model';
import { ManagementReportItemModel } from '../models/reports/management-report/management-report-item.model';
import { ProfitLossReportFilterModel } from '../models/reports/profit-loss-report/profit-loss-report-filter.model';
import { ProfitLossReportItemModel } from '../models/reports/profit-loss-report/profit-loss-report-item.model';
import { CertificatesReportItemModel } from '../models/reports/certificates-report/certificates-report-item.model';
import { CertificatesReportFilterModel } from '../models/reports/certificates-report/certificates-report-filter.model';
import { RemakeItemReportItemModel } from '../models/reports/remake-item-report/remake-item-report-item.model';
import { RemakeItemReportFilterModel } from '../models/reports/remake-item-report/remake-item-report-filter.model';

@Injectable()
export class ReportService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public async getMySalesReport(filter: MySalesReportFilterModel): Promise<ModelList<MySalesReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/my-sales-report`,
            data: filter
        }));
        const items = data.items.map(x => new MySalesReportItemModel(x));
        return new ModelList<MySalesReportItemModel>(items, data.totalCount);
    }

    public async getItemsReport(filter: ItemsReportFilterModel): Promise<ModelList<ItemsReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/items-report`,
            data: filter
        }));
        const items = data.items.map(x => new ItemsReportItemModel(x));
        return new ModelList<ItemsReportItemModel>(items, data.totalCount);
    }

    public async getExpensesReport(filter: ExpensesReportFilterModel): Promise<ModelList<ExpensesReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/expenses-report`,
            data: filter
        }));
        const items = data.items.map(x => new ExpensesReportItemModel(x));
        return new ModelList<ExpensesReportItemModel>(items, data.totalCount);
    }

    public async getCashflowReport(filter: CashflowReportFilterModel): Promise<ModelList<CashflowReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/cashflow-report`,
            data: filter
        }));
        const items = data.items.map(x => new CashflowReportItemModel(x));
        return new ModelList<CashflowReportItemModel>(items, data.totalCount);
    }

    public async getPaymentReport(filter: PaymentReportFilterModel): Promise<ModelList<PaymentReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/payment-report`,
            data: filter
        }));
        const items = data.items.map(x => new PaymentReportItemModel(x));
        return new ModelList<PaymentReportItemModel>(items, data.totalCount);
    }

    public async getOverdueReport(filter: OverdueReportFilterModel): Promise<ModelList<OverdueReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/overdue-report`,
            data: filter
        }));
        const items = data.items.map(x => new OverdueReportItemModel(x));
        return new ModelList<OverdueReportItemModel>(items, data.totalCount);
    }

    public async getCashflowTrailersReport(filter: CashflowTrailorsReportFilterModel): Promise<ModelList<CashflowTrailorsReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/cashflow-trailers-report`,
            data: filter
        }));
        const items = data.items.map(x => new CashflowTrailorsReportItemModel(x));
        return new ModelList<CashflowTrailorsReportItemModel>(items, data.totalCount);
    }

    public async getBookingBuddyReport(filter: BookingBuddyReportFilterModel): Promise<ModelList<BookingBuddyReportItemsModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/booking-buddy-report`,
            data: filter
        }));
        const items = data.items.map(x => new BookingBuddyReportItemsModel(x));
        return new ModelList<BookingBuddyReportItemsModel>(items, data.totalCount);
    }

    public async getAlterationsReport(filter: AlterationReportFilterModel): Promise<ModelList<AlterationReportItemsModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/alterations-report`,
            data: filter
        }));
        const items = data.items.map(x => new AlterationReportItemsModel(x));
        return new ModelList<AlterationReportItemsModel>(items, data.totalCount);
    }

    public async getTotalNCPBankReport(filter: TotalNCPBankReportFilterModel): Promise<ModelList<TotalNCPBankReportItemsModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/total-ncp-bank-report`,
            data: filter
        }));
        const items = data.items.map(x => new TotalNCPBankReportItemsModel(x));
        return new ModelList<TotalNCPBankReportItemsModel>(items, data.totalCount);
    }

    public async getClientReassignReport(filter: ClientReassignReportFilterModel): Promise<ModelList<ClientReassignReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/client-reassign-report`,
            data: filter
        }));
        const items = data.items.map(x => new ClientReassignReportItemModel(x));
        return new ModelList<ClientReassignReportItemModel>(items, data.totalCount);
    }

    public async getOverviewReport(filter: OverviewReportFilterModel): Promise<ModelList<OverviewReportItemsModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/overview-report`,
            data: filter
        }));
        const items = data.items.map(x => new OverviewReportItemsModel(x));
        return new ModelList<OverviewReportItemsModel>(items, data.totalCount);
    }

    public async getNewClientReport(filter: NewClientReportFilterModel): Promise<ModelList<NewClientReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/new-client-report`,
            data: filter
        }));
        const items = data.items.map(x => new NewClientReportItemModel(x));
        return new ModelList<NewClientReportItemModel>(items, data.totalCount);
    }

    public async getTopClientReport(filter: TopClientReportFilterModel): Promise<ModelList<TopClientReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/top-client-report`,
            data: filter
        }));
        const items = data.items.map(x => new TopClientReportItemModel(x));
        return new ModelList<TopClientReportItemModel>(items, data.totalCount);
    }

    public async getTopServiceReport(filter: FilterReportBaseModel): Promise<ModelList<TopServiceReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/top-service-report`,
            data: filter
        }));
        const items = data.items.map(x => new TopServiceReportItemModel(x));
        return new ModelList<TopServiceReportItemModel>(items, data.totalCount);
    }

    public async getClothierQBReport(filter: FilterReportBaseModel): Promise<ModelList<ClothierQBReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/clothier-qb-report`,
            data: filter
        }));
        const items = data.items.map(x => new ClothierQBReportItemModel(x));
        return new ModelList<ClothierQBReportItemModel>(items, data.totalCount);
    }

    public async getManagementReport(filter: ManagementReportFilterModel): Promise<ModelList<ManagementReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/management-report`,
            data: filter
        }));
        const items = data.items.map(x => new ManagementReportItemModel(x));
        return new ModelList<ManagementReportItemModel>(items, data.totalCount);
    }

    public async getProfitLossReport(filter: ProfitLossReportFilterModel): Promise<ModelList<ProfitLossReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/profit-loss-report`,
            data: filter
        }));
        const items = data.items.map(x => new ProfitLossReportItemModel(x));
        return new ModelList<ProfitLossReportItemModel>(items, data.totalCount);
    }

    public async getCertificatesReport(filter: CertificatesReportFilterModel): Promise<ModelList<CertificatesReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/certificates-report`,
            data: filter
        }));
        const items = data.items.map(x => new CertificatesReportItemModel(x));
        return new ModelList<CertificatesReportItemModel>(items, data.totalCount);
    }

    public async getRemakeItemReport(filter: RemakeItemReportFilterModel): Promise<ModelList<RemakeItemReportItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/reports/remake-item-report`,
            data: filter
        }));
        const items = data.items.map(x => new RemakeItemReportItemModel(x));
        return new ModelList<RemakeItemReportItemModel>(items, data.totalCount);
    }
}
