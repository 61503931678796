import { ObjectUtil } from '../../../utility/object.util';

export class ItemsReportItemModel {
    public rowNumber: number;
    public product: string;
    public line: string;
    public tax: number;
    public units: number;
    public total: number;
    public totalBeforTax: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
