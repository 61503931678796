import { DateUtil } from "src/app/core/utility/date.util";
import { ObjectUtil } from "src/app/core/utility/object.util";

export class BookingBuddyReportItemsModel {
    public rowNumber: number;
    public created: Date;
    public meetingDate: Date;
    public address: string;
    public author: string;
    public city: string;
    public clientName: string;
    public clientId: number;
    public company: string;
    public country: string;
    public email: string;
    public gender: string;
    public postalCode: string;
    public province: string;
    public selectedRep: string;
    public selectedEmail: string;
    public linkedinLocation: string;
    public used: boolean;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            created: DateUtil.fromString,
            meetingDate: DateUtil.fromString,
        });
    }
}
