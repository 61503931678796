<div [ngClass]="isDarkTheme ? 'dark-theme':'light-theme'" class="bar-chart">
    <div [ngStyle]="{'min-width': width+'px','min-height': height+'px'}" class="chart-body">
        <div class="row title">
            @if(icon)
            {
            <div style="width: 70px; margin-right: 4px" class="col-1">
                <img [src]="icon" class="logo" />
            </div>
            }
            <div class="col">
                @if(chartTitle)
                {
                <div class="row chart-title-text-1">{{chartTitle}}</div>
                }
                @if(additionalTitle)
                {
                <div class="row chart-title-text-2">{{additionalTitle }}</div>
                }
            </div>
        </div>
        @if(!isLoading) {
        @if (data && labels.length > 0) {
        <div style="display: block">
            <base-chart [datasets]="data" [labels]="labels" [options]="options" [height]="height" [width]="width"
                [chartType]="barChartType">
            </base-chart>
        </div>
        } @else {
        <div class="chart-placeholder">
            <div>
                <div class="row">
                    <img [src]="placeholderImage" alt="IMG">
                </div>
                <div style="margin-top: 20px;" class="row">
                    <div>{{placeholderText}}</div>
                </div>
            </div>
        </div>
        }
        }
    </div>
    <ngx-spinner [name]="spinnerName" [fullScreen]="false" [template]="loaderImg" color="red" size="large"
        bdColor="rgba(0,0,0,0.7)">
        <p [ngStyle]="{'color': isDarkTheme ? 'white' : 'black'}" class="loading">Loading Chart...</p>
    </ngx-spinner>
</div>