import { ObjectUtil } from "src/app/core/utility/object.util";

export class ManagementReportItemModel {
    public rowNumber: number;
    public salesManager: string;
    public rep: string;
    public period: string;
    public cashflow: number;
    public cashflowCAD: number;
    public bonusAmountPercentage: number;
    public bonusAmount: number;
    public bonusAmountCAD: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
