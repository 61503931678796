import { DateUtil } from "src/app/core/utility/date.util";
import { ObjectUtil } from "src/app/core/utility/object.util";

export class PaymentReportItemsModel {
    public rowNumber: number;
    public cashflowLocal: number;
    public trailersDiscountsLocal: number;
    public finalCashflowLocal: number;
    public maxENCPLocal: number;
    public actualENCPLocal: number;
    public maxExpensesCoveredLocal: number;
    public actualExpensesCoveredLocal: number;
    public companyPaidExpensesLocal: number;
    public repPaidExpensesLocal: number;
    public totalActualExpensesLocal: number;
    public expensesReimbursedLocal: number;
    public cashflowLocalReturnedPercent: number;
    public trailersDiscountLocalReturned: number;
    public cashflowTravel: number;
    public trailersDiscountsTravel: number;
    public finalCashflowTravel: number;
    public maxENCPTravel: number;
    public actualENCPTravel: number;
    public maxExpensesCoveredTravel: number;
    public actualExpensesCoveredTravel: number;
    public companyPaidExpensesTravel: number;
    public repPaidExpensesTravel: number;
    public totalActualExpensesTravel: number;
    public expensesReimbursedTravel: number;
    public cashflowTravelReturnedPercent: number;
    public trailersDiscountTravelReturned: number;
    public paymentId: number;
    public repName: string;
    public finalCashflowTotal: number;
    public unitsTotal: number;
    public payonUnits: number;
    public period1Base: number;
    public grossStartingPayPeriod1: number;
    public maxNCP: number;
    public actualNCP: number;
    public rolloverNCP: number;
    public carryForwardNCP: number;
    public maxAltsCovered: number;
    public totalNCPBank: number;
    public actualAltsCovered: number;
    public ukAlts: number;
    public writeOffs: number;
    public returnedWriteOffs: number;
    public repPaidAlts: number;
    public altsReimbursed: number;
    public totalMonthPay: number;
    public onboardMinPay: number;
    public expensesReimbursed: number;
    public grossStartingPay: number;
    public actualMonthPay: number;
    public period1Paid: number;
    public period2Pay: number;
    public overall: number;
    public nonENCPExpensesReimburse: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            dateOfOrder: DateUtil.fromString,
            dateOfCashflow: DateUtil.fromString,
        });
    }
}
