import { OrderStatus } from "src/app/core/enums/order-status.enum";
import { DateUtil } from "src/app/core/utility/date.util";
import { ObjectUtil } from "src/app/core/utility/object.util";

export class CertificatesReportItemModel {
    public rowNumber: number;
    public reporter: string;
    public orderStatus: OrderStatus;
    public client: string;
    public clientId: number;
    public gcPicture: string;
    public gcDelivered: string;
    public orderId: number;
    public firm: string;
    public item: string;
    public line: string;
    public retailPrice: number;
    public retailPriceInCAD: number;
    public currency: string;
    public provinceOfSale: string;
    public salesTax: number;
    public salesTaxInCAD: number;
    public totalCollectedWithTax: number;
    public totalCollectedWithTaxInCAD: number;
    public cadRate: number;
    public dateOfOrder: string;
    public giftCertificateCode: string;
    public gcRetailValueInCAD: number;
    public isClient: boolean;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            dateOfOrder: DateUtil.fromString
        });
    }
}
