import { Injectable } from '@angular/core';
import { UserContextService } from './user-context.service';

@Injectable()
export class AccessService {
    constructor(
        private userContextService: UserContextService) {
    }

    public get hasAccessToReportExportBtn() {
        return this.userContextService.isAdmin()
                || this.userContextService.isAccounting();
    }
}
