import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpRestService } from "./http-client/http-rest-client.service";
import { ItemReportTableModel } from "../models/tables/item-report-table.model";
import { FilterReportBaseModel } from "../models/reports/filter-report-base.model";
import { TableType } from "../enums/table-type.enum";
import { ModelList } from "../models/models-list";
import { BookingBuddyTableModel } from "../models/tables/booking-buddy-table.model";

@Injectable()
export class TableService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public async getItemReportTable(filter: FilterReportBaseModel): Promise<ModelList<ItemReportTableModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/table/get-table`,
            data: { type: TableType.ItemReportTable, filter }
        }));

        return new ModelList<ItemReportTableModel>(data.map(x => new ItemReportTableModel(x)), data.length);
    }

    public async getBookingBuddyReportTable(filter: FilterReportBaseModel): Promise<ModelList<BookingBuddyTableModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/table/get-table`,
            data: { type: TableType.BookingBuddyReportTable, filter }
        }));

        return new ModelList<BookingBuddyTableModel>(data.map(x => new BookingBuddyTableModel(x)), data.length);
    }
}
