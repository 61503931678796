import { ClientReassignReportFilterModel } from './../models/reports/client-reassign-report/client-reassign-report-filter.model';
import { TotalNCPBankReportFilterModel } from './../models/reports/total-ncp-bank-report/total-ncp-bank-report-filter.model';
import { OverdueReportFilterModel } from './../models/reports/overdue-report/overdue-report-filter.model';
import { CashflowTrailorsReportFilterModel } from './../models/reports/сashflow-trailors-report/сashflow-trailors-report-filter.model';
import { AlterationReportFilterModel } from '../models/reports/alteration-report/alteration-report-fliter.model';
import { CashflowReportFilterModel } from '../models/reports/cashflow-report/cashflow-report-fliter.model';
import { ExpensesReportFilterModel } from './../models/reports/expenses-report/expenses-report-filter.model';
import { HttpRestService } from './http-client/http-rest-client.service';
import { MySalesReportFilterModel } from './../models/reports/my-sales-report/my-salse-report-filter.model';
import { BaseService } from "./base.service";
import { Injectable } from '@angular/core';
import { BookingBuddyReportFilterModel } from '../models/reports/booking-buddy-report/booking-buddy-report-filter.model';
import { PaymentReportFilterModel } from '../models/reports/payment-report/payment-report-filter.model';
import { OverviewReportFilterModel } from '../models/reports/overview-report/overview-report-filter.model';
import { NewClientReportFilterModel } from '../models/reports/new-client-report/new-client-report-filte.model';
import { TopClientReportFilterModel } from '../models/reports/top-client-report/top-client-report-filter.model';
import { FilterReportBaseModel } from '../models/reports/filter-report-base.model';
import { ManagementReportFilterModel } from '../models/reports/management-report/management-report-filter.model';
import { ProfitLossReportFilterModel } from '../models/reports/profit-loss-report/profit-loss-report-filter.model';
import { CertificatesReportFilterModel } from '../models/reports/certificates-report/certificates-report-filter.model';
import saveAs from 'file-saver';
import { ItemsReportFilterModel } from '../models/reports/items-report/items-report-filter.model';

@Injectable()
export class ExportReportService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public async exportMySalesReport(filter: MySalesReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/my-sales-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "My Sales Report.csv");
    }

    public async exportItemReport(filter: ItemsReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/items-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Items Report.csv");
    }

    public async exportExpensesReport(filter: ExpensesReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/expenses-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Expenses Report.csv");
    }

    public async exportCashflowReport(filter: CashflowReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/cashflow-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Cashflow Report.csv");
    }

    public async exportPaymentReport(filter: PaymentReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/payment-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Payment Report.csv");
    }

    public async exportOverdueReport(filter: OverdueReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/overdue-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Overdue Report.csv");;
    }

    public async exportCashflowTrailersReport(filter: CashflowTrailorsReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/cashflow-trailers-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Cashflow Trailers Report.csv");
    }

    public async exportBookingBuddyReport(filter: BookingBuddyReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/booking-buddy-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Booking Buddy Report.csv");
    }

    public async exportAlterationsReport(filter: AlterationReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/alterations-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Alterations Report.csv");
    }

    public async exportTotalNCPBankReport(filter: TotalNCPBankReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/total-ncp-bank-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Total NCP Bank Report.csv");
    }

    public async exportClientReassignReport(filter: ClientReassignReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/client-reassign-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Client Reassign Report.csv");
    }

    public async exportOverviewReport(filter: OverviewReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/overview-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Overview Report.csv");
    }

    public async exportNewClientReport(filter: NewClientReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/new-client-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "New Client Report.csv");
    }

    public async exportTopClientReport(filter: TopClientReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/top-client-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Top Client Report.csv");
    }

    public async exportTopServiceReport(filter: FilterReportBaseModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/top-service-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Top Service Report.csv");
    }

    public async exportClothierQBReport(filter: FilterReportBaseModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/clothier-qb-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Clothier QB Report.csv");
    }

    public async exportManagementReport(filter: ManagementReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/management-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Management Report.csv");
    }

    public async exportProfitLossReport(filter: ProfitLossReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/profit-loss-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Profit Loss Report.csv");
    }

    public async exportCertificatesReport(filter: CertificatesReportFilterModel): Promise<void> {
        const blob = await this.restService.post({
            url: `/reports-as-csv/certificates-report`,
            responseType: 'blob',
            data: filter
        });

        this.validateBlob(blob);
        saveAs(blob as Blob, "Certificates Report.csv");
    }
}
