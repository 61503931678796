import { ObjectUtil } from "src/app/core/utility/object.util";

export class OverviewReportItemsModel {
    public rowNumber: number;
    public repName: string;
    public periodFrom: string;
    public periodTo: string;
    public cashflow: number;
    public averagePackageSizeInCad: number;
    public localUnits: number;
    public sales: number;
    public ncp: number;
    public encp: number;
    public repRating: number;
    public baseSales: number;
    public baseNcp: number;
    public baseEncp: number;
    public baseRepRating: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
