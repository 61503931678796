import { ObjectUtil } from "src/app/core/utility/object.util";

export class NewClientReportItemModel {
    public rowNumber: number;
    public reporter: string;
    public nonDiscounted: number;
    public discounted: number;
    public total: number;

    constructor(data) {
        ObjectUtil.extend(data, this);
    }
}
