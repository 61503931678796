import { UserContextService } from '../service/user-context.service';
import { AppRouterService } from '../service/app-router.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AlterationReportGuard {
    constructor(
        private userContextService: UserContextService,
        private router: AppRouterService) {
    }

    public canActivate() {
        if (this.userContextService.isAdmin() ||
            this.userContextService.isRep() ||
            this.userContextService.isAlterationApprover() ||
            this.userContextService.isAccounting()) {
            return true;
        }
        this.router.openAccessDeniedPage();
        return false;
    }

    public canActivateChild() {
        return this.canActivate();
    }
}
